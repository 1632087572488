/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { GMX_API } from '~/config'

import { type HttpResponse } from './generated/Api'
import { Api as ApiInstance } from './generated/Api'

export const api = new ApiInstance({
  baseApiParams: {
    credentials: 'include',
    mode: 'cors',
  },
  baseUrl: GMX_API,
})

export class ClientError<TErrorCode extends string> extends Error {
  readonly name: 'ClientError'
  readonly code: TErrorCode
  readonly status: number
  readonly url: string
  constructor({
    cause,
    code: code,
    status,
    url,
  }: {
    cause: unknown
    code: TErrorCode
    status: number
    url: string
  }) {
    super(`${status} ${url}: ${code}`, { cause })
    this.name = 'ClientError'
    this.code = code
    this.status = status
    this.url = url
  }
}

export class ServerError extends Error {
  readonly name: 'ServerError'
  readonly message: string
  readonly status: number
  readonly url: string
  constructor({
    cause,
    message,
    status,
    url,
  }: {
    cause: unknown
    message: string
    status: number
    url: string
  }) {
    super(`${status} ${url}: ${message}`, { cause })
    this.name = 'ServerError'
    this.message = message
    this.status = status
    this.url = url
  }
}

/**
 * Check if an `unknown` is a client error. Do so, with a particular request in mind to get strictly
 * typed error codes.
 *
 * Caveat: Keep in mind that there is no runtime check to see if the error came from the specified
 * endpoint. This is just to help you cast into the right type.
 *
 * Do you not know which endpoint the endpoint the error came from?
 * Use
 * ```typescript
 * if (error instanceof ClientError) {
 *   error.code // code is any
 * }
 * ```
 *
 * @example
 * ```typescript
 * try {
 * } catch (error) {
 *   if (!isClientError<typeof api.games.getGames>(error)) {
 *     throw error
 *   }
 *   switch (error.code) {
 *     case '...':
 *     default:
 *       unreachable(error.code)
 *   }
 * }
 * ```
 */
export const isClientError = <
  TRequest extends (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<HttpResponse<{ data: any }, { errors: { code: string }[] }>> | never = never,
>(
  error: unknown,
  // _fromRequest: TRequest,
): error is TRequest extends never
  ? never
  : ClientError<
      ReturnType<TRequest> extends Promise<
        HttpResponse<unknown, { errors: { code: infer Error extends string }[] }>
      >
        ? Error
        : never
    > => error instanceof ClientError

export const handleResponse = async <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TResponse extends HttpResponse<{ data: any }, { errors: { code: string }[] }>,
  TData = TResponse extends HttpResponse<{ data: infer Data }> ? Data : never,
  TError extends string = TResponse extends HttpResponse<
    unknown,
    { errors: { code: infer Error extends string }[] }
  >
    ? Error
    : never,
>(
  response: TResponse | Promise<TResponse>,
) => {
  const awaitedResponse = await response
  const data = awaitedResponse.data as { data: TData }
  const error = awaitedResponse.error as { errors?: { code: TError }[] } | null
  if (error) {
    // Missing error => broke the backend. Win!
    if (!error.errors) {
      throw new ServerError({
        cause: error,
        message: JSON.stringify(awaitedResponse.error),
        status: awaitedResponse.status,
        url: awaitedResponse.url,
      })
    }
    // Once upon a time, BE added this list to return multiple errors at once. They never did...
    // Let's not waste code time iterating over an error list that is never longer than one.
    const [firstError] = error.errors ?? []
    // If there is no errorcode, then there's something very broken.
    if (!firstError) {
      throw new ServerError({
        cause: error,
        message: 'Received error, but no error codes',
        status: awaitedResponse.status,
        url: awaitedResponse.url,
      })
    }
    // Throw the received errorcode
    throw new ClientError({
      cause: error,
      code: firstError.code,
      status: awaitedResponse.status,
      url: awaitedResponse.url,
    })
  }
  return data.data
}
